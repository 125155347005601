(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("mobxReact"), require("ReactRouterDOM"));
	else if(typeof define === 'function' && define.amd)
		define("privateNetLibraryView", ["React", "antd", "mobxReact", "ReactRouterDOM"], factory);
	else if(typeof exports === 'object')
		exports["privateNetLibraryView"] = factory(require("React"), require("antd"), require("mobxReact"), require("ReactRouterDOM"));
	else
		root["privateNetLibraryView"] = factory(root["React"], root["antd"], root["mobxReact"], root["ReactRouterDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__) {
return 