import React from 'react'

const AuthComponent = Loader.loadBusinessComponent('AuthComponent');
const IconSpan = Loader.loadBaseComponent('IconSpan');
const LibHeader = Loader.loadBusinessComponent("MonitorLibrary", "LibHeader");


class MachineDetail extends React.Component {

  render() {
    let { onEdit, libDetail, userId, actionName, children=null } = this.props;
    const managerIds = libDetail.userIds || [];
    return (
      <div className='monitee-lib-info-wrapper'>
        <LibHeader title={'专网库详情'}>
          <div className='lib-info-edit'>
          {managerIds.includes(userId) && (
            <AuthComponent actionName={actionName}>
              <IconSpan 
                className='span-btn'  
                mode='inline'
                icon="icon-S_Edit_Edit" 
                onClick={onEdit} 
                label='编辑' 
              />
            </AuthComponent>
          )}
          </div>
        </LibHeader>
        { children }
      </div> 
    )
  }
}

export default MachineDetail